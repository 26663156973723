<template>
<b-modal
    id="gallery-create-modal"
    title="Gallery Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">Gallery Create</h2>
    </template>
    <validation-observer ref="GalleryCreateFormValidation">
    <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
        <template #label>
            Gallery Name <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Gallery Name"
            required
            @keydown="checkMaxLength($event, 'name', 200)"
        />
        </b-form-group>

        <!-- <b-form-group label-for="intro">
        <template #label>
            Intro
        </template>
        <b-form-textarea
            id="intro"
            v-model="intro"
            placeholder="Intro"
            v-restrict="regex"
            rows="3"
            max-rows="6"
        ></b-form-textarea>          
        </b-form-group>

        <b-form-group label-for="description">
        <template #label>
            Description
        </template>
        <quill-editor v-model="description" />
        </b-form-group> -->
        
        <b-form-group label-for="hospital">
          <template #label>
            Hospital <span class="text-danger">*</span>
          </template>   
          <validation-provider
                #default="{ errors }"
                name="Hospital"
                rules="required" >   
          <v-select label="hospitalLabel" v-model="hospital" placeholder="Select Hospital" :options="formattedHospitals" autoscroll :clearable="true" required></v-select>
          <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> 
        </b-form-group>


        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>
        
        <validation-provider
        #default="{ errors }"
        name="Image"
        rules = "required"
        >
        <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="documents"
            description="Upload an image in PNG, JPG, or JPEG format."
        >
        <template #label>
            Images <span class="text-danger">*</span>
        </template>
            <b-form-file
            id="documents"
            placeholder="Choose images"
            accept=".png, .jpg, .jpeg"
            multiple
            required
            @change="updateFilesList"
            ></b-form-file>
            <p v-if="selectedImageNames.length > 0" class="mt-2">
            <strong>Selected Images:</strong>
            <ul>
                <li v-for="(imageName, index) in selectedImageNames" :key="index">
                {{ imageName }}
                <span @click="removeImage(index)" class="ml-2" style="cursor: pointer;">&#10006;</span>
                </li>
            </ul>
            </p>
        </b-form-group>
        <p class="error">{{ errors[0] }}</p>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
        <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1"
        >
            Save
        </b-button>
        <b-button
            type="reset"
            variant="outline-secondary"
            pill
            @click="reset"
        >
            Reset
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import util from "@/util.js";
  import { quillEditor } from 'vue-quill-editor';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
  import { BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormFile } from 'bootstrap-vue';
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,    
      quillEditor,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormSelect,
      BFormFile
    },
    mixins: [util],
    data() {
      return {
        required,
        name: "",
        intro: "",
        description: "",     
        status: "",
        hospital: null,
        hospitals: [],
        options: [
          { value: "", text: "-- Select --" },
          { value: true, text: "Enable" },
          { value: false, text: "Disable" },
        ],
        regex: /^[a-zA-Z ]*$/,
        files: [],
        selectedImageNames: []
      };
    },
    methods: {
      ...mapActions({
        createGallery: "appData/createGallery",
        getHospitalsPublic: "appData/getHospitalsPublic",
      }),
      checkMaxLength(event, fieldName, maxLength) {
        if (
          event.target.value.length >= maxLength &&
          event.key !== "Backspace" &&
          event.key !== "Delete"
        ) {
          event.preventDefault();
          this[fieldName] = event.target.value.slice(0, maxLength);
        }
      },
      async submit() {
        try {
          const formData = new FormData();
          formData.append('name', this.name);
          formData.append('status', this.status);
          formData.append('hospital', this.hospital.id);
          formData.append('created_by', this.getLoggedInUser.id);
          formData.append('updated_by', this.getLoggedInUser.id);
          this.files.forEach(file => {
            formData.append('images', file);
          });
  
          const res = await this.createGallery(formData);
          if (res.status === 200) {
            this.$swal({
              title: "Gallery is created successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("gallery-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.name = "";
        this.files = [];
        this.description = "";
        this.hospital = null;
        this.selectedImageNames = [];
        this.status = "";
      },
      updateFilesList(event) {
        const newFiles = Array.from(event.target.files);
        const existingFileNames = this.files.map(file => file.name);
        const uniqueNewFiles = newFiles.filter(file => !existingFileNames.includes(file.name));
        this.files = [...this.files, ...uniqueNewFiles];
        this.selectedImageNames = this.files.map(file => file.name);
      },
      removeImage(index) {
        this.files.splice(index, 1);
        this.selectedImageNames.splice(index, 1);
      }
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
      formattedHospitals() {
      return this.hospitals.map(hospital => {
        return {
          ...hospital,
          hospitalLabel: `${hospital.name}`
        };
      });
    }
    },
    async mounted(){
        const res_hospitals = await this.getHospitalsPublic();
        this.hospitals = res_hospitals.data;
  }
  };
  </script>
  
  <style></style>
  