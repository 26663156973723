<template>
<b-modal
    id="gallery-edit-modal"
    title="Gallery Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">Gallery Edit</h2>
    </template>
    <validation-observer ref="galleryEditFormValidation">
    <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
        <template #label>
            Gallery Name <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Tourist Attraction Name"
            @keydown="checkMaxLength($event, 'name', 500)"
            required
        />
        </b-form-group>

        <!-- <b-form-group label-for="intro">
        <template #label>
            Intro
        </template>
        <b-form-textarea
            id="intro"
            v-model="intro"
            placeholder="Intro"
            v-restrict="regex"
            rows="3"
            max-rows="6"
        ></b-form-textarea>          
        </b-form-group>

        <b-form-group label-for="description">
        <template #label>
            Description
        </template>
        <quill-editor id="description" v-model="description" />
        </b-form-group> -->
        <b-form-group label-for="hospital">
          <template #label>
            Hospital <span class="text-danger">*</span>
          </template>   
          <validation-provider
                #default="{ errors }"
                name="Hospital"
                rules="required" >   
                <v-select label="name" v-model="hospital" placeholder="Select Hospital" :options="hospitals" :reduce="option => option.id" autoscroll :clearable="true"></v-select>
          <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> 
        </b-form-group>

        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>

        <validation-provider
        #default="{ errors }"
        name="Image"
        >
        <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="documents"
            description="Upload an image in PNG, JPG, or JPEG format."
        >
        <template #label>
            Images <span class="text-danger">*</span>
        </template>
            <b-form-file
            id="documents"
            placeholder="Choose Images"
            drop-placeholder="Drop files here..."
            accept=".png, .jpg, .jpeg"
            multiple
            @change="updateFilesList"
            ></b-form-file>
            <p class="error">{{ errors[0] }}</p>
        </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2">
        <strong>Already Uploaded Images</strong>
        <div v-if="existingImages.length > 0" class="d-flex flex-wrap">
            <div v-for="(image, index) in existingImages" :key="index" class="position-relative m-1">
            <img :src="image.url" alt="uploaded image" class="img-thumbnail" style="width: 100px; height: 100px;">
            <b-button size="sm" variant="danger" class="position-absolute" style="top: 0; right: 0;" @click="removeExistingImage(index)">
                &times;
            </b-button>
            </div>
        </div>
        </b-form-group>

        <b-form-group class="mt-2">
        <strong>Newly Selected Images</strong>
        <ul v-if="selectedImageNames.length > 0">
            <li v-for="(imageName, index) in selectedImageNames" :key="index">
            {{ imageName }}
            <span @click="removeImage(index)" class="ml-2" style="cursor: pointer;">&#10006;</span>
            </li>
        </ul>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
        <b-button
            type="submit"
            variant="info"
            pill
            class="mr-1"
        >
            Update
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import util from "@/util.js";
  import { quillEditor } from 'vue-quill-editor';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';
  import { BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect, BFormFile } from 'bootstrap-vue';
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      quillEditor,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BFormSelect,
      BFormFile
    },
    mixins: [util],
    props: {
      gallery: Object,
    },
    data() {
      return {
        name: "",
        intro: "",
        description: "",
        files: [],
        hospital: null,
        hospitals: [],
        existingImages: [],
        selectedImageNames: [],
        deletedImageIds: [],
        status: "",
        options: [
          { value: '', text: '-- Select --' },
          { value: true, text: 'Enable' },
          { value: false, text: 'Disable' },
        ],
        regex: /^[a-zA-Z ]*$/,
      };
    },
    async mounted() {
        const res_hospitals = await this.getHospitalsPublic();
        this.hospitals = res_hospitals.data;
      if (this.gallery && typeof this.gallery === 'object') {
        this.name = this.gallery.name;
        this.hospital = this.gallery.hospital;
        this.status = this.gallery.status;
        this.existingImages = this.gallery.images.map(image => { 
          return { id: image.id, url: image.image }; 
        }) || [];
      }
    },
    methods: {
      ...mapActions({
        updateGallery: "appData/updateGallery",
        getHospitalsPublic: "appData/getHospitalsPublic",
      }),
      imageUrl(url) {
        return process.env.VUE_APP_MEDIA +"/media/"+ url;
      },
      checkMaxLength(event, fieldName, maxLength) {
        if (
          event.target.value.length >= maxLength &&
          event.key !== "Backspace" &&
          event.key !== "Delete"
        ) {
          event.preventDefault();
          this[fieldName] = event.target.value.slice(0, maxLength);
        }
      },
      async submit() {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('status', this.status);
        if (this.hospital){
            formData.append('hospital', this.hospital)
        }
        this.files.forEach(file => {
          formData.append('images', file);
        });
        if (this.deletedImageIds.length > 0) {
          formData.append('delete_images', JSON.stringify(this.deletedImageIds));
        }
        formData.append('updated_by', this.getLoggedInUser.id); 
  
        try {
          const res = await this.updateGallery({
            payload: formData,
            pk: this.gallery.id,
          });
          if (res.status === 200) {
            this.$swal({
              title: "Gallery updated successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("gallery-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.name = "";
        this.status = "";
        this.files = [];
        this.selectedImageNames = [];
        this.deletedImageIds = [];
        this.existingImages = this.gallery.images.map(image => {
          return { id: image.id, url: image.url || image.filePath };
        }) || [];
      },
      updateFilesList(event) {
        const newFiles = Array.from(event.target.files);
        const existingFileNames = this.files.map(file => file.name);
        const uniqueNewFiles = newFiles.filter(file => !existingFileNames.includes(file.name));
        this.files = [...this.files, ...uniqueNewFiles];
        this.selectedImageNames = this.files.map(file => file.name);
      },
      removeImage(index) {
        this.files.splice(index, 1);
        this.selectedImageNames.splice(index, 1);
      },
      removeExistingImage(index) {
        const removedImage = this.existingImages.splice(index, 1)[0];
        if (removedImage && removedImage.id) {
          this.deletedImageIds.push(removedImage.id);
        }
      }
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    },
  };
  </script>
  
  <style>
  
  </style>
  